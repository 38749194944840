import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./sensorList.scss";
import TableView from '../table/table'
import CollapseTableView from "../collapsibleTable/collapsTable";
import { useTranslation } from "react-multi-lang";
import {
	getUser,
	updateUser,
	updateDeviceSettingsUserID,
	poweruserFromUsers,
	DeletePowerUser,
	AllPowerUsers
} from "../../services/constant";
import { getDataFromApi } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { SetSensorList ,SetAllPowerUsers} from "../../redux/action";
import { store } from "react-notifications-component";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import { Paper, Grid, Button } from "@material-ui/core";
import _ from "lodash";
const header = [
	{ id: "devicename", label: "Device Name" },
	{ id: "deviceid", label: "DeviceID" },
	{ id: "uniueid", label: "UniqueID" },
	{ id: "action", label: "Action" },
];

const header1 = [
	{ id: "Name", label: "Name Poweruser" },
	{ id: "address", label: "Address" },
	{ id: "action", label: "Action" },

]
export default function SensorList(props: any) {
	const t = useTranslation();
	const history = useHistory();
	const selectedUSerId = useSelector((state: any) => state.userId);
	const selectedUserDetail = useSelector((state: any) => state.sensorList);
	const dispatch = useDispatch();
	const [allSensorList, setAllSensorList] = React.useState([]);
	const [allPowerUsers, setAllPowerUsersData] = React.useState([]);
	const [apiCall, setApiCall] = React.useState(false);
	const user = useSelector((state: any) => state.LoginData);
	
	const getSensorListByUserId = async () => {
		let request = _.clone(getUser);
		request.url = request.url + "/?UserID=" + `"${selectedUSerId}"`;
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const result: any = await getDataFromApi(request);
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
			dispatch(SetSensorList([]));
			
			if (result.data.UserID) {
				if (user.attributes["custom:role"] === "Poweruser") {
					request = _.clone(AllPowerUsers);
					request.url = request.url + `/?UserID=${user.attributes.sub}`;
					request.header = {
					  "Content-Type": "application/json",
					  Authorization: user.signInUserSession.idToken.jwtToken,
					};
					const result1: any = await getDataFromApi(request);
					if (result1.data.message === "The incoming token has expired") {
					  history.push("/");
					} else {
						let arrr : any = {}
						result1.data.Poweruser.PoweruserUsecases.map((item : any )=> {
							if(result.data.Users.Devices[0][Object.keys(item)[0]]) {
								arrr[Object.keys(item)[0]] = result.data.Users.Devices[0][Object.keys(item)[0]]
							}
						})
						let newUseCase : any = [];
						newUseCase.push(arrr)
						result.data.Users.Devices = newUseCase;
				  }
				}
				
				dispatch(SetSensorList(result.data));
				setAllSensorList(result.data.Users.Devices);
			}
			setApiCall(true);
		}
	};

	useEffect(() => {
		getSensorListByUserId();
		if (user.attributes["custom:role"] === "admin") {
			PoweruserOfSelectedUser()
		}
	}, []);

	const updateSensorDetail = async (sensorId: any, cIndex: any) => {
		let allSensor: any = selectedUserDetail;
		let DeviceID: any =
			allSensor.Users.Devices[0][sensorId][cIndex].DeviceId;
		allSensor.Users.Devices[0][sensorId].splice(cIndex, 1);

		if (allSensor.Users.Devices[0][sensorId].length === 0) {
			allSensor.Users.Devices[0] = _.omit(
				allSensor.Users.Devices[0],
				sensorId
			);
		}
		let request = _.clone(updateUser);
		request.url = request.url;
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const contacts = [];
		if (user.attributes["custom:custom:contactno1"]) {
			contacts.push(user.attributes["custom:custom:contactno1"]);
		}
		if (user.attributes["custom:custom:contactno2"]) {
			contacts.push(user.attributes["custom:custom:contactno2"]);
		}
		if (user.attributes["custom:custom:contactno3"]) {
			contacts.push(user.attributes["custom:custom:contactno3"]);
		}
		const data = {
			UserID: allSensor.UserID,
			Users: allSensor.Users,
		};

		request.data = data;
		const result: any = await getDataFromApi(request);
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else if (result.flag) {
			let request = _.clone(updateDeviceSettingsUserID);
			request.url = request.url;
			request.header = {
				"Content-Type": "application/json",
				Authorization: user.signInUserSession.idToken.jwtToken,
			};
			const data = {
				UserID: "",
				DeviceID: DeviceID,
			};

			request.data = data;
			const result1: any = await getDataFromApi(request);
			if (result1.data.message === "The incoming token has expired") {
				history.push("/");
			} else {
				store.addNotification({
					message: "Update Successfully !..",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1000,
						onScreen: true,
					},
				});
				getSensorListByUserId();
			}
		}
	};

	const addSensors = () => {
		history.push("/addSensors");
	};

	const addPoweruser = () => {
		history.push('/addPoweruser')
	}

	const PoweruserOfSelectedUser = async () => {
		
		let request = _.clone(poweruserFromUsers);
		request.url = request.url + `/?UserID=${selectedUSerId}`;
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const result: any = await getDataFromApi(request);
		
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
			
			dispatch(SetAllPowerUsers([]));
				dispatch(SetAllPowerUsers(result.data));
				setAllPowerUsersData(result.data);
			setApiCall(true);
		}
	}

	const deletePowerUser = async (powerUserID : any) => {
		
		let request = _.clone(DeletePowerUser);
		request.url = request.url + `/?UserID=${selectedUSerId}`;
        request.header = {
            "Content-Type": "application/json",
            Authorization: user.signInUserSession.idToken.jwtToken,
        };
		const data : any = {
			"UserID" : selectedUSerId,
			"powerUserID" : powerUserID
		}
       request.data = data
        const result: any = await getDataFromApi(request);
		
        if (result.data.message === "The incoming token has expired") {
            history.push("/");
        } else{
			getSensorListByUserId()
		   PoweruserOfSelectedUser()
        }
	}

	return (
		<div className="sensorListMainDiv">
			<Paper className="paperM">
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<span>
							{selectedUserDetail.Users
								? selectedUserDetail.Users.Name
								: ""}
							{", "}
							{selectedUserDetail.Users
								? selectedUserDetail.Users.Address.Street
								: ""}{" "}
							{selectedUserDetail.Users
								? selectedUserDetail.Users.Address.Place
								: ""}{" "}
							{selectedUserDetail.Users
								? selectedUserDetail.Users.Address.Postcode
								: ""}
						</span>
					</Grid>
				</Grid>
			</Paper>
			<CollapseTableView
				tableHeader={header}
				tableBody={allSensorList}
				apiCall={apiCall}
				updateSensor={updateSensorDetail}
			/>
			<div className="addSensors">
				<AddCircleOutlinedIcon onClick={addSensors} />
			</div>
			<br />
			{
				user.attributes['custom:role'] === 'Poweruser' ? "" :
				<>
			<TableView
				tableHeader1={header1}
				tableBody={allPowerUsers}
				apiCall={apiCall}
				deletePowerUser={deletePowerUser}
			/>
			
				<div className="addSensors">
					<Button id="addPower" onClick={addPoweruser}>add Poweruser</Button>
				</div>		
				</>		
			}			
		</div>
	);
}
